.stickynav {
  background: hsla(210, 2%, 16%, 0.341);
  width: max-content;
  padding: 0.7rem 1.7rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
  overflow-y: auto;
  z-index: 4; /* highest z-index */
}

.stickynav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: white;
  font-size: 1.4rem;
}
