@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100&family=Roboto+Slab&display=swap");
:root {
  --main-color: rgb(255, 255, 255);
  --nav-color: transparent;
  --nav-font-color: #ffffff;
  --main-body-color: #222152;
  --transition-color: #222152;
}
html, body {
  height: 100%;
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: "Roboto Slab", "Segoe UI", "Roboto", "Oxygen", "Outfit", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/* Change the background color here */
  color: var(--main-color);
  background-image: url("./components/images/bg-02-free-img.png");
  background-repeat: no-repeat; /* Prevents the background from repeating */
  background-size: cover; /* Makes the background image cover the entire viewport */
  background-position: center center; /* Centers the background image */

  /* Additional styles for the body */
}
.slide-in, .slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999; /* this ensures the element is on top of all other layers */
}

.slide-in {
  background: var(--transition-color);
  transform-origin: right;
}

.slide-out {
  background: var(--transition-color);
  transform-origin: left;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




@media (max-width: 768px) {
  body {
    background-image: url("./components/images/bg-02-free-img.png");
    background-repeat: repeat-y;

 }


  .footer {
    height: 100px; /* reduced from 3% */
    padding: 10px 25px; /* reduced from 20px 50px */
  }

  
  .typography-style {
    width: 92%;
    text-align: center;
    display: block;
  }
  
  .link-style {
    text-decoration: none;
    color: white;
  }

  .link-style:hover {
    color: #F7A4D7; 
  }

  .menu-item-root, .link-style {
    transition: all 0.3s ease;
  }

}



