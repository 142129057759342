.skill-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 100px;
}

.skill-wrap p {
  font-size: 15px;
}

.skill-wrap p1 {
  color: var(--main-body-color);
  font-size: 15px;
}

.resume-container {
  display: flex;
  flex-direction: row; /* If you want them in a row */
  justify-content: space-between; /* Distribute the columns evenly */
  align-items: stretch;
}

.column-paper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  min-height: auto;
  backdrop-filter: blur(15px);
  background: hsla(204, 2%, 44%, 0.341) !important;
}

.column-paper h2 {
  color: rgb(255, 255, 255);
}

.column-paper p {
  color: rgb(255, 255, 255);
}

.column-paper p1 {
  color: #f7a4d7;
}

.column-container {
  text-align: center;
}

.center-content {
  text-align: center;
}

.icon-wrapper {
  display: inline-flex; /* Ensures the circle doesn't take up full width */
  justify-content: center; /* Horizontally centers the icon within the circle */
  align-items: center; /* Vertically centers the icon within the circle */
  width: 100px; /* Set the width and height to create a circle */
  height: 100px;
  border-radius: 50%; /* Makes the element a circle */
  background-color: var(--main-body-color);
  margin: 10px;
}
@media (max-width: 768px) {
  .column-paper {
    /* other styles */
    min-width: 80vw; /* Use viewport width units to ensure 80% width */
    margin: auto; /* Centers the box */
  }

  .skill-wrap {
    justify-content: center;
    align-items: center;
    padding: 0; /* Remove the padding on mobile for better responsiveness */
    margin: 20px; /* Adjust margin as needed */
  }
}
