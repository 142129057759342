.story-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 100px;

}

.second-display {
  display: flex;
  justify-content: center;
  align-items: center;
  background: hsla(204, 2%, 44%, 0.341);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.684); /* Customize shadow values as needed */
  padding: 20px;
  backdrop-filter: blur(15px);
  border-radius: 8px;
}

.name-story {
  margin-top: -30px;
  background-color: #cccccc54;
  border-radius: 10px;
  width: fit-content;
  padding: 0 10px;  /* No vertical padding, retained horizontal padding */
}
.name-story h3 {

  color: rgb(255, 255, 255);
  font-size:20px;
  line-height: 1px;
 
}

.avatar-story {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center;    /* Align items horizontally in the center */
}


.avatar-story img.avatar {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  margin: 50px;
  border: 5px solid #ffffff87;
}

.story-image {
  min-width: 400px;
  margin-right: 50px;
}

.first-display {
  animation: fadeIn 2s forwards; /* 2s fade-in animation */
  opacity: 0; /* Start with 0 opacity */
  transition: opacity 1s ease-in-out 5s; /* 1s transition for the opacity after a 5s delay */
}

.fade-out {
  opacity: 0; /* Fade out to 0 opacity */
}

.first-display h1 {
  margin-top: 200px;
  font-size: 100px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Mobile adaptive styles */
@media (max-width: 768px) {
  .story-container {
    flex-direction: column; /* Change to column layout for mobile */
    margin: 50px 10px 100px 10px; /* Reduce margins */
   
  }
  
  .second-display {
    flex-direction: column; /* Stack content vertically for mobile */
    padding: 10px; /* Reduce padding */
    max-width: 95%;
    margin:5px;
    text-align: center;
    
  }

  .story-image {
    
    min-width: auto; /* Allow image to resize for mobile */
    width: 50%; /* Set width to 100% */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add bottom margin for spacing */
  }
  
  .first-display h1 {
    font-size: 50px; /* Reduce font size for mobile */
  }


  .story-container p{
    font-size: 15px;
  }

  .story-text span{
    font-weight: 500;
  }



  
}
